import * as React from "react";
import { Box, Fade, styled, Typography } from "@mui/material";

export type HeroBannerProps = {
  heading: string;
  subHeading?: string;
  image: string;
};

const StyledElement = styled(Box)<HeroBannerProps>((props) => {
  return {
    textAlign: "right",
    padding: props.theme.spacing(1),
    [props.theme.breakpoints.up("md")]: {
      padding: props.theme.spacing(10),
    },
    background: `url(${props.image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    "h1, h2": {
      color: "#fff",
      position: "relative",
    },
    "& .heroBackdrop": {
      backgroundColor: props.theme.ami3goPalette.blue.main,
      opacity: 0.85,
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
  };
});

export default React.memo(function HeroBanner(props: HeroBannerProps) {
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setChecked(true), 500);
  }, []);
  return (
    <StyledElement {...props}>
      <Fade in={checked} {...(checked ? { timeout: 1000 } : {})}>
        <Box>
          <Box className="heroBackdrop" />
          <Typography variant="h1" gutterBottom>
            {props.heading}
          </Typography>
          {props.subHeading && (
            <Typography variant="h2">{props.subHeading}</Typography>
          )}
        </Box>
      </Fade>
    </StyledElement>
  );
});
