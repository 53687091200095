import * as React from "react";

import heroImage from "../../assets/stock/coffee-meeting.webp";
import { ContactUs, HeroBanner } from "../../components";
import { IPage } from "..";

const PageComponent = React.memo(function Page() {
  return (
    <>
      <HeroBanner
        image={heroImage}
        heading="Get in touch"
        subHeading="Find out how we can help your business grow"
      />

      <ContactUs />
    </>
  );
});

const page: IPage = {
  title: "Contact us",
  route: {
    path: "/contact-us",
    element: <PageComponent />,
  },
};

export default page;
