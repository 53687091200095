import * as React from "react";
import { Box, Grid } from "@mui/material";

type SectionProps = {
  id: string;
  title: string;
  image: string;
  children?: React.ReactNode;
};

export default React.memo(function Section(props: SectionProps) {
  return (
    <Box
      id={props.id}
      component="section"
      sx={{
        my: 10,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <img
            src={props.image}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            alt={props.title}
            loading="lazy"
          />
        </Grid>
        {props.children && (
          <Grid item xs={12} md={6}>
            <Box p={3}>{props.children}</Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
});
