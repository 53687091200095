import * as React from "react";
import { Box, Grid } from "@mui/material";

type SectionProps = {
  id: string;
  image: string;
  children?: React.ReactNode;
};

export default React.memo(function Section(props: SectionProps) {
  return (
    <Box
      id={props.id}
      component="section"
      sx={{
        my: 10,
        backgroundImage: `url(${props.image})`,
      }}
    >
      <Grid container spacing={3}>
        {props.children && (
          <Grid item xs={12} sm={10} xl={7}>
            <Box
              sx={{
                transform: "translateY(2.5rem)",
                p: 5,
                backgroundColor: (theme) => theme.ami3goPalette.blue.main,
                color: (theme) => theme.ami3goPalette.blue.contrastText,
              }}
            >
              {props.children}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
});
