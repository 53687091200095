import * as React from "react";
import CookieConsent from "react-cookie-consent";
import { Link, Route, Routes } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  useTheme,
} from "@mui/material";

import logoBlue from "./assets/logo/company-logo-blue.svg";
import logoDark from "./assets/logo/company-logo-dark.svg";
import logoGreen from "./assets/logo/company-logo-green.svg";
import logoLightBlue from "./assets/logo/company-logo-light-blue.svg";
import logoWhite from "./assets/logo/company-logo-white.svg";
import { Footer, Header } from "./components";
import { useImagePreloader } from "./hooks";
import {
  ContactUs,
  CookieSettings,
  Home,
  Platforms,
  PrivacyPolicy,
  TermsOfUse,
  WhatWeDo,
  WhoWeAre,
} from "./pages";

const routes = [
  WhatWeDo,
  WhoWeAre,
  Platforms,
  ContactUs,
  PrivacyPolicy,
  CookieSettings,
  TermsOfUse,
  Home,
];

const images: string[] = [
  logoBlue,
  logoDark,
  logoGreen,
  logoLightBlue,
  logoWhite,
];

export default function AppContainer() {
  const theme = useTheme();
  const { imagesPreloaded } = useImagePreloader(images);

  if (!imagesPreloaded) {
    return (
      <Backdrop
        open={true}
        transitionDuration={500}
        style={{
          backgroundColor: theme.ami3goPalette.darkGrey.main,
          color: theme.ami3goPalette.darkGrey.contrastText,
          zIndex: theme.zIndex.tooltip + 1,
        }}
      >
        <Box
          p={2}
          display="flex"
          flexGrow={1}
          justifyContent="center"
          textAlign="center"
        >
          <Box my={5}>
            <CircularProgress size="5em" thickness={5} />
          </Box>
        </Box>
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        flexDirection: "column",
        display: "flex",
        flex: "1 1 auto",
      }}
    >
      <Header />

      <Container
        maxWidth={false}
        disableGutters
        sx={{
          flexDirection: "column",
          display: "flex",
          flex: "1 0 auto",
          padding: 0,
        }}
      >
        <Box>
          <Routes>
            {routes.map((r) => (
              <Route key={r.title} {...r.route} />
            ))}
          </Routes>
        </Box>
      </Container>

      <Footer />

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieconsent_status"
        style={{
          color: theme.ami3goPalette.green.contrastText,
          backgroundColor: theme.ami3goPalette.green.main,
        }}
        buttonStyle={{
          backgroundColor: theme.ami3goPalette.blue.main,
          color: theme.ami3goPalette.blue.contrastText,
        }}
        expires={150}
      >
        This website uses cookies to ensure you get the best experience on our
        website. To change your settings, visit{" "}
        <Link to={CookieSettings.route.path}>Cookie Settings</Link>
      </CookieConsent>

      <CssBaseline />
    </Box>
  );
}
