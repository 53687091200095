import * as React from "react";
import { CookiesProvider } from "react-cookie";
import { AppInsightsProvider } from "@ami3go/diagnostics";
import { ThemeProvider } from "@mui/material/styles";

import AppContainer from "./AppContainer";
import CustomRouter from "./CustomRouter";
import { applicationSettings, createHistory, createTheme } from "./utils";

// setup site styles
const theme = createTheme();

// get history
const history = createHistory();

export default function App() {
  return (
    <CustomRouter history={history}>
      <AppInsightsProvider
        instrumentationKey={
          applicationSettings.applicationInsightsInstrumentationKey
        }
        history={history}
      >
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <AppContainer />
          </CookiesProvider>
        </ThemeProvider>
      </AppInsightsProvider>
    </CustomRouter>
  );
}
