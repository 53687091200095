import {
  createTheme as muiCreateTheme,
  responsiveFontSizes,
  SimplePaletteColorOptions,
} from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    ami3goPalette: {
      darkGrey: SimplePaletteColorOptions;
      blue: SimplePaletteColorOptions;
      grey: SimplePaletteColorOptions;
      lightGrey: SimplePaletteColorOptions;
      lightBlue: SimplePaletteColorOptions;
      green: SimplePaletteColorOptions;
    };
  }

  interface ThemeOptions {
    ami3goPalette?: {
      darkGrey?: SimplePaletteColorOptions;
      blue?: SimplePaletteColorOptions;
      grey?: SimplePaletteColorOptions;
      lightGrey?: SimplePaletteColorOptions;
      lightBlue?: SimplePaletteColorOptions;
      green?: SimplePaletteColorOptions;
    };
  }
}

const darkGrey: SimplePaletteColorOptions = {
  main: "#333a41",
  light: "#5B6167",
  dark: "#23282D",
  contrastText: "#c6e3d7",
};

const blue: SimplePaletteColorOptions = {
  main: "#263c53",
  light: "#51667f",
  dark: "#00162a",
  contrastText: "#fff",
};

const grey: SimplePaletteColorOptions = {
  main: "#4d6176",
  light: "#7a8ea5",
  dark: "#23374a",
  contrastText: "#fff",
};

const lightGrey: SimplePaletteColorOptions = {
  main: "#a1acb7",
  light: "#B3BCC5",
  dark: "#707880",
  contrastText: "#000",
};

const lightBlue: SimplePaletteColorOptions = {
  main: "#9eb8d4",
  light: "#d0eaff",
  dark: "#6f88a3",
  contrastText: "#223b54",
};

const green: SimplePaletteColorOptions = {
  main: "#c6e3d7",
  light: "#f9ffff",
  dark: "#95b1a6",
  contrastText: "#223b54",
};

export const createTheme = () => {
  const theme = muiCreateTheme({
    ami3goPalette: {
      darkGrey: darkGrey,
      blue: blue,
      grey: grey,
      lightGrey: lightGrey,
      lightBlue: lightBlue,
      green: green,
    },
    palette: {
      primary: green,
      secondary: blue,
      text: {
        primary: "#33393f",
      },
    },
    typography: {
      fontFamily: ["Questrial", "sans-serif"].join(","),
      fontSize: 16,
      h1: {
        fontSize: 90,
        fontWeight: "medium",
        letterSpacing: "0.08em",
      },
      h2: {
        fontSize: 60,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      h3: {
        fontSize: 40,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      h4: {
        fontSize: 30,
        fontWeight: "medium",
      },
      h5: {
        fontSize: 18,
        fontWeight: "bold",
      },
      h6: {
        fontSize: 15,
        fontWeight: "medium",
        textTransform: "uppercase",
      },
    },
  });

  return responsiveFontSizes(theme);
};
