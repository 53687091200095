import * as React from "react";
import { Link } from "react-router-dom";
import { Container, Link as MuiLink, Typography } from "@mui/material";

import heroImage from "../../assets/stock/pexels-ketut-subiyanto-4963437.webp";
import { HeroBanner } from "../../components";
import { CookieSettings, IPage, PrivacyPolicy } from "..";

const PageComponent = React.memo(function Page() {
  return (
    <>
      <HeroBanner image={heroImage} heading="Terms of use" />

      <Container>
        <Typography variant="h2" gutterBottom>
          TERMS AND CONDITIONS OF USE (&quot;Terms of Use&quot;)
        </Typography>
        <Typography variant="h2" gutterBottom>
          WHO WE ARE AND HOW TO CONTACT US
        </Typography>
        <Typography variant="body1" gutterBottom>
          <a href="/">https://www.AMI3GO.com/</a> is a website (the
          <b>&quot;Website&quot;</b>) operated by AMI3GO Limited (
          <b>&quot;AMI3GO Ltd&quot;</b>,<b>&quot;We&quot;</b>, or
          <b>&quot;Us&quot;</b>). We are a limited liability company organised
          under the laws of the United Kingdom of Great Britain. Our principle
          place of business is AMI3GO Ltd, Colony, 5 Piccadilly Place,
          Manchester, M1 3BR.
        </Typography>
        <Typography variant="body1" gutterBottom>
          To contact us, please email
          <MuiLink href="mailto:enquiry@ami3go.com">enquiry@ami3go.com</MuiLink>
          or telephone <MuiLink href="tel:01613271825">0161 3271825</MuiLink>.
        </Typography>

        <Typography variant="h2" gutterBottom>
          BY USING OUR SITE YOU ACCEPT THESE TERMS
        </Typography>
        <Typography variant="body1" gutterBottom>
          By using our Website, you confirm that you accept these Terms of Use
          and that you agree to comply with them. If you do not agree to these
          Terms of Use, you must not use our Website. We recommend that you
          print a copy of these Terms of Use for future reference. Our Website
          may be used for your informational purposes only. Our Website is made
          available free of charge.
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms of Use may refer to the following additional terms, which
          also apply to your use of our Website:
        </Typography>
        <ol>
          <li>
            Our <Link to={PrivacyPolicy.route.path}>Privacy Policy</Link>, which
            sets out the terms on which we process any personal data we collect
            from you, or that you provide to us. By using our Website, you
            consent to such processing and you warrant that all data provided by
            you is accurate.
          </li>
          <li>
            Our <Link to={CookieSettings.route.path}>Cookie Settings</Link>,
            which sets out information about the cookies on our Website.
          </li>
        </ol>

        <Typography variant="h2" gutterBottom>
          MINORS
        </Typography>
        <Typography variant="body1" gutterBottom>
          PERSONS UNDER THE AGE OF 18 MUST ONLY USE THE WEBSITE AND PROVIDE
          PERSONAL INFORMATION IF THEY HAVE THE CONSENT AND SUPERVISION OF A
          PARENT OR GUARDIAN.
        </Typography>

        <Typography variant="h2" gutterBottom>
          WE MAY MAKE CHANGES
        </Typography>
        <Typography variant="body1" gutterBottom>
          We amend these Terms of Use from time to time. Every time you wish to
          use our Website, please check these Terms of Use to ensure you
          understand the terms that apply at that time. These terms were most
          recently updated on June 20, 2017.
        </Typography>

        <Typography variant="h2" gutterBottom>
          WE MAY MAKE CHANGES TO, SUSPEND OR WITHDRAW OUR WEBSITE
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update and change our Website from time to time to reflect
          changes to our services, our users&apos; needs and our business
          priorities.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not guarantee that our Website, or any content on it, will
          always be available or be uninterrupted. We may suspend, withdraw or
          restrict the availability of all or any part of our Website for
          business and operational reasons.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You are also responsible for ensuring that all persons who access our
          Website through your internet connection are aware of these Terms of
          Use and other applicable terms and conditions, and that they comply
          with them.
        </Typography>

        <Typography variant="h2" gutterBottom>
          USE OF WEBSITE
        </Typography>
        <Typography variant="body1" gutterBottom>
          You may not use our Website to commit any violation of federal, state,
          local, or international laws, regulations, or other governmental
          requirements. We reserve the right to report any activity that may
          violate any law or regulation to appropriate law enforcement
          officials, regulators, or other third parties, without prior notice to
          you, and we will co-operate with such authorities by disclosing your
          identity to them. In the event of such a violation, your right to use
          our Website will cease immediately.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You are prohibited from violating, or attempting to violate, the
          security of our Website. Any such violation may result in criminal and
          civil liabilities to you. Examples of prohibited security violations
          include, but are not limited to:
        </Typography>
        <ol>
          <li>
            logging into a server or account that you are not authorized to
            access;
          </li>
          <li>
            accessing data or taking any action to obtain services not intended
            for you or your use;
          </li>
          <li>
            attempting to probe, scan, or test the vulnerability of any system,
            subsystem, or network related to the Website or to any AMI3GO
            Consulting computer or computer system;
          </li>
          <li>
            tampering, hacking, modifying, or otherwise corrupting or breaching
            security or authentication measures;
          </li>
          <li>
            introducing or transmitting material that contains viruses, Trojan
            horses, worms, time bombs, logic bombs, cancelbots, or other
            technologically malicious or harmful computer programming routines
            or engines with the intent or effect of damaging, destroying,
            disrupting, or otherwise impairing, a computer&apos;s functionality
            or the operation of the Website;
          </li>
          <li>
            interfering with, intercepting, or expropriating any system, data,
            or information;
          </li>
          <li>
            interfering with service to any user, host, or network including,
            but not limited to, by means of overloading, &quot;flooding,&quot;
            &quot;mailbombing,&quot; or &quot;crashing&quot; any system,
            subsystem, or network related to the Website or otherwise; and
          </li>
          <li>
            attacking the Website via a denial-of-service attack or a
            distributed denial-of service attack.
          </li>
        </ol>

        <Typography variant="h2" gutterBottom>
          FUNCTIONALITY DISCLAIMER
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not guarantee that the functions contained in the Website will
          be uninterrupted or error-free, that this Website or its server will
          be free of viruses or other harmful components, or that defects will
          be corrected even if We are aware of them. You are responsible for
          configuring your information technology, computer programs and
          platform to access our Website. You should use your own virus
          protection software. The material in this Website may include
          technical inaccuracies or typographical errors.
        </Typography>

        <Typography variant="h2" gutterBottom>
          CONTENT
        </Typography>
        <Typography variant="body1" gutterBottom>
          We are the owner or the licensee of all intellectual property rights
          in our Website, and in the material published on it. Those works are
          protected by copyright laws and treaties around the world. All such
          rights are reserved. You are authorized to copy, print, or distribute
          such content only as follows:
        </Typography>
        <ol>
          <li>
            You may print individual pages, but you may not copy or download a
            large portion of the Website (e.g., no bots, webcrawlers, spiders,
            etc. that &quot;harvest&quot; the Website).
          </li>
          <li>
            You may download, print, distribute, and use pages from the Website
            for your personal informational, non-commercial purposes only. Any
            copies of such documents or pages must not alter the original
            Website content and must retain any copyright notices appearing
            therein.
          </li>
          <li>
            Our status (and that of any identified contributors) as the authors
            of content on our Website must always be acknowledged.
          </li>
          <li>
            You may not frame this Website or any portion thereof. Linking to
            this Website in a way that does not otherwise infringe upon our
            trademark interests is permitted, provided that (i) the link does
            not falsely imply or suggest that We have endorsed, or are
            affiliated with, the linked website, and (ii) you follow the
            &quot;rules about linking to our site&quot; which are stated below.
          </li>
          <li>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </li>
          <li>
            You must not use any part of the content on our Website for
            commercial purposes without obtaining a license to do so from us or
            our licensors.
          </li>
          <li>
            If you print off, copy or download any part of our Website in breach
            of these Terms of Use, your right to use our Website will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </li>
        </ol>
        <Typography variant="body1" gutterBottom>
          We reserve the right to terminate all or any portion of the above
          authorizations to you at any time in our sole discretion.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Except as expressly provided above, you may not otherwise copy,
          display, download, distribute, modify, reproduce, republish,
          retransmit or create derivative works based upon any information,
          content, or materials contained in the Website or any medium
          (including electronic or hard copy) without our express prior written
          consent. Nothing contained on the Website shall be construed as
          conferring by implication, estoppel, or otherwise any license or right
          under any intellectual property right of AMI3GO Consulting or any
          third party, except as expressly provided above.
        </Typography>

        <Typography variant="h2" gutterBottom>
          TRADEMARKS
        </Typography>
        <Typography variant="body1" gutterBottom>
          The trademarks, service marks, and logos of AMI3GO Consulting, and its
          affiliates, used and displayed on the Website are our registered and
          unregistered trademarks. Requests to use trademarks owned by other
          companies which may be mentioned on our Website should be directed to
          such other companies. We actively and vigorously enforce our
          intellectual property rights. Our trademarks, service marks, and logos
          may not be used in any way, including in advertising or publicity
          pertaining to distribution of materials on our Website, without our
          prior written permission.
        </Typography>

        <Typography variant="h2" gutterBottom>
          COMPLIANCE WITH LOCAL LAWS
        </Typography>
        <Typography variant="body1" gutterBottom>
          We control and administer our Website from our offices within the
          United Kingdom, and the Website and its contents are intended to
          comply with applicable laws and regulations in the United Kingdom.
          Although the information on the Website is accessible to users outside
          of the United Kingdom, We make no representation that the content of
          this Website complies with laws and regulations of any jurisdiction
          outside of the United Kingdom or that such content is appropriate or
          available for use in any such jurisdiction. Access to any content on
          the Website from locations where such content is illegal is
          prohibited. If you choose to access this Website from locations
          outside of the United Kingdom, you are responsible for compliance with
          any applicable local laws and regulations. You may not use or export
          any of the information on this Website in violation of US export laws
          and regulations.
        </Typography>

        <Typography variant="h2" gutterBottom>
          WEBSITES WE LINK TO
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may provide links on the Website that allow you to connect with
          third party websites. We have no control over the contents of those
          sites or resources and We provide them to you for your information
          only. You access these sites at your own risk. We are not responsible
          for the contents of any linked site, nor does the appearance of a link
          imply our endorsement of them. Such links should not be interpreted as
          approval by us of those linked websites or information you may obtain
          from them.
        </Typography>

        <Typography variant="h2" gutterBottom>
          RULES ABOUT LINKING TO OUR SITE
        </Typography>
        <Typography variant="body1" gutterBottom>
          You may link to our home page, provided you do so in a way that is
          fair and legal and does not damage our reputation or take advantage of
          it. You must not establish a link in such a way as to suggest any form
          of association, approval or endorsement on our part where none exists.
          You must not establish a link to our Website in any website that is
          not owned by you. Our Website must not be framed on any other site,
          nor may you create a link to any part of our Website other than the
          home page.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We reserve the right to withdraw linking permission without notice.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you wish to link to or make any use of content on our Website other
          than that set out above, please contact
          <MuiLink href="mailto:enquiry@ami3go.com">enquiry@ami3go.com</MuiLink>
          .
        </Typography>

        <Typography variant="h2" gutterBottom>
          SUBMISSIONS
        </Typography>
        <Typography variant="body1" gutterBottom>
          All submissions, documents, materials, postings, comments, remarks,
          suggestions, ideas, graphics, or other information communicated from
          users to this Website (<b>&quot;User Material&quot;</b>) are, and will
          forever be, the property and intellectual property of AMI3GO
          Consulting, and you hereby assign all your intellectual property
          rights in such User Material to Us. We will not be required to treat
          any such User Material as confidential. Further, We will be entitled
          to use any such User Material for any purpose whatsoever, commercial
          or otherwise, without compensation to you or anyone else. Do not
          submit User Material that is unlawful, defamatory, abusive, obscene,
          not in accordance with these Terms of Use, or that will violate any
          right of any third party, including copyright, trademark, privacy, or
          other personal or proprietary right(s). If you do, We shall be
          entitled to remove such User Material with or without notice to you.
        </Typography>

        <Typography variant="h2" gutterBottom>
          PRIVACY
        </Typography>
        <Typography variant="body1" gutterBottom>
          For an explanation of our policies related to the collection, use, and
          storage of information, please read our
          <Link to={PrivacyPolicy.route.path}>Privacy Policy</Link>.
        </Typography>

        <Typography variant="h2" gutterBottom>
          INDEMNIFICATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree to indemnify, defend, and hold harmless AMI3GO Consulting,
          its affiliates, officers, directors, employees, agents, suppliers, and
          third-party partners from and against all losses, expenses, damages,
          and costs, including reasonable attorneys&apos; fees and expenses,
          resulting or arising from, or incurred in connection with, any
          violations by you of these Terms of Use.
        </Typography>

        <Typography variant="h2" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography variant="body1" gutterBottom>
          WE DO NOT EXCLUDE OR LIMIT IN ANY WAY OUR LIABILITY TO YOU WHERE IT
          WOULD BE UNLAWFUL TO DO SO. AMI3GO CONSULTING AND ITS AFFILIATES WILL
          NOT BE LIABLE FOR ANY DAMAGES OR INJURY, INCLUDING, BUT NOT LIMITED
          TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
          THAT RESULT FROM THE USE OF (OR THE INABILITY TO USE) THIS WEBSITE AND
          ITS CONTENTS, OR A WEBSITE LINKED TO THIS WEBSITE, INCLUDING ANY
          DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR,
          OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION, COMPUTER VIRUS,
          LINE FAILURE, OR OTHER COMPUTER MALFUNCTION.
        </Typography>

        <Typography variant="h2" gutterBottom>
          DISCLAIMER
        </Typography>
        <Typography variant="body1" gutterBottom>
          THIS WEBSITE IS PROVIDED BY AMI3GO CONSULTING ON AN &quot;AS IS&quot;
          AND &quot;AS AVAILABLE&quot; BASIS. THE CONTENT ON OUR WEBSITE IS
          PROVIDED FOR GENERAL INFORMATION ONLY. IT IS NOT INTENDED TO AMOUNT TO
          ADVICE ON WHICH YOU SHOULD RELY. YOU MUST OBTAIN PROFESSIONAL OR
          SPECIALIST ADVICE BEFORE TAKING, OR REFRAINING FROM, ANY ACTION ON THE
          BASIS OF THE CONTENT ON OUR WEBSITE.
        </Typography>
        <Typography variant="body1" gutterBottom>
          AMI3GO CONSULTING MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
          EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS WEBSITE OR THE
          INFORMATION INCLUDED ON THIS WEBSITE. YOU EXPRESSLY AGREE THAT YOUR
          USE OF THIS WEBSITE IS AT YOUR SOLE RISK. AMI3GO CONSULTING DOES NOT
          WARRANT THAT THE INFORMATION IN THIS WEBSITE IS ACCURATE, COMPLETE,
          CURRENT, RELIABLE, OR CORRECT, THAT THIS WEBSITE WILL BE AVAILABLE AT
          ANY PARTICULAR TIME OR LOCATION, OR THAT THE WEBSITE IS FREE OF
          HARMFUL COMPONENTS.
        </Typography>

        <Typography variant="h2" gutterBottom>
          SEVERABILITY
        </Typography>
        <Typography variant="body1" gutterBottom>
          If any provision of these Terms of Use is deemed unlawful, void, or
          for any reason unenforceable, then that provision will be deemed
          severable from these Terms of Use and will not affect the validity and
          enforceability of the remaining provisions.
        </Typography>
      </Container>
    </>
  );
});

const page: IPage = {
  title: "Terms of use",
  route: {
    path: "/terms-of-use",
    element: <PageComponent />,
  },
};

export default page;
