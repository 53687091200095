import * as React from "react";
import { Container, Grid, Link, styled } from "@mui/material";
import {
  faAt,
  faLocationPin,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledGridCell = styled(Grid)(({ theme }) => {
  return {
    "&:not(:last-child)": {
      [theme.breakpoints.up("md")]: {
        borderRight: `1px solid ${theme.ami3goPalette.lightBlue.main}`,
      },
    },
  };
});

const StyledAddress = styled("address")(({ theme }) => {
  return {
    marginTop: theme.spacing(1),
    fontStyle: "normal",
    textAlign: "center",
    "& .svg-inline--fa": {
      fontSize: "2em",
      color: theme.ami3goPalette.lightBlue.main,
      marginBottom: theme.spacing(2),
    },
    a: {
      color: theme.palette.text.primary,
      textDecoration: "underline",
    },
  };
});

export default React.memo(function ContactUs() {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <StyledGridCell item xs={12} md={4}>
          <StyledAddress>
            <FontAwesomeIcon icon={faLocationPin} />
            <br />
            AMI3GO Ltd,
            <br />
            Colony,
            <br />
            5 Piccadilly Place,
            <br />
            Manchester,
            <br />
            M1 3BR
          </StyledAddress>
        </StyledGridCell>

        <StyledGridCell item xs={12} md={4}>
          <StyledAddress>
            <FontAwesomeIcon icon={faAt} />
            <br />
            <Link href="mailto:enquiry@ami3go.com">enquiry@ami3go.com</Link>
          </StyledAddress>
        </StyledGridCell>

        <StyledGridCell item xs={12} md={4}>
          <StyledAddress>
            <FontAwesomeIcon icon={faPhone} />
            <br />
            <Link href="tel:01613271825">0161 3271825</Link>
          </StyledAddress>
        </StyledGridCell>
      </Grid>
    </Container>
  );
});
