import React from "react";
import { Container, Typography } from "@mui/material";

import sectionImage from "../../assets/stock/network-3396348_640.jpg";
import { Type03Left } from "../../components/sections";

export default React.memo(function AIMachineLearningSection() {
  return (
    <Container maxWidth="lg">
      <Type03Left
        id="ai-machine-learning"
        title="AI Machine Learning"
        image={sectionImage}
      >
        <Typography variant="h3" gutterBottom>
          AI Machine Learning
        </Typography>
        <Typography variant="h4" gutterBottom>
          Learn from your data, create incredible digital experiences, and
          automate your digital process
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our AI specialists can utilise conversational AI to envision and
          produce transformative voice and text based interactive services.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Using your data warehouse, we can implement evolutionary AI to create
          predictive models to diffuse automation throughout your business,
          allowing you to provide more efficient services to your clients and
          customers
        </Typography>
      </Type03Left>
    </Container>
  );
});
