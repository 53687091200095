import React from "react";
import { Container, Typography } from "@mui/material";

import sectionImage from "../../assets/stock/office-1209640_640.jpg";
import { Type03Right } from "../../components/sections";

export default React.memo(function ConsultancySection() {
  return (
    <Container maxWidth="lg">
      <Type03Right id="consultancy" title="Consultancy" image={sectionImage}>
        <Typography variant="h3" gutterBottom>
          Consultancy
        </Typography>
        <Typography variant="h4" gutterBottom>
          Your vision, our know-how
        </Typography>
        <Typography variant="body1" gutterBottom>
          At AMI3GO, the consultancy process is an in-depth conversation that
          will help you realise the full potential and value of software in your
          business. All of our consultants are senior IT experts that are
          experienced at board level.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We offer IT Consultancy on a flexible basis. It is entirely dependent
          upon the level of support your business requires and the type of
          projects that you are undertaking. Upon understanding your
          requirements, our consultants will guide you on the amount of time
          they think that you will require, but this is of course also open to
          our clients feedback too.
        </Typography>
      </Type03Right>
    </Container>
  );
});
