import * as React from "react";
import { Link } from "react-router-dom";
import { Container, Link as MuiLink, Typography } from "@mui/material";

import heroImage from "../../assets/stock/pexels-ketut-subiyanto-4963437.webp";
import { HeroBanner } from "../../components";
import { CookieSettings, IPage } from "..";

const PageComponent = React.memo(function Page() {
  return (
    <>
      <HeroBanner
        image={heroImage}
        heading="Privacy policy"
        subHeading="AMI3GO Ltd"
      />

      <Container>
        <Typography variant="h3" gutterBottom>
          1. Who are we?
        </Typography>
        For the purposes of current UK data protection legislation, the Data
        Controller is AMI3GO Ltd, Colony, 5 Piccadilly Place, Manchester, M1
        3BR, referred to throughout this document as AMI3GO.
        <br />
        <br />
        This Privacy Policy lays out how AMI3GO will handle the personal data
        that it collects via:
        <ul>
          <li>
            its website{" "}
            <a href="https://www.AMI3GO.com">https://www.AMI3GO.com</a>
          </li>
          <li>its recruitment and employment processes</li>
          <li>incoming telephone calls and electronic messages</li>
        </ul>
        <Typography variant="h3" gutterBottom>
          2. Security Measures
        </Typography>
        AMI3GO takes security measures in line with data protection regulations.
        AMI3GO has security measures in place designed to prevent data loss, to
        preserve data integrity, and to regulate access to the data. Only
        authorised AMI3GO employees, and authorised employees of our Third-Party
        service providers processing data on our behalf, have access to your
        personal data. All AMI3GO employees who have access to your personal
        data are required to adhere to the AMI3GO Privacy Policy. In addition,
        contracts are in place with all third-party service providers acting as
        data processors for AMI3GO that have access to your personal data, to
        ensure that the level of security required is in place, and that your
        personal data is processed only as instructed by AMI3GO.
        <Typography variant="h3" gutterBottom>
          3. How we use your information
        </Typography>
        This privacy notice tells you what to expect when we collect personal
        information, or you provide personal information to us. It applies to
        information about:
        <ul>
          <li>Visitors to our websites</li>
          <li>Use of Cookies by AMI3GO</li>
          <li>Individuals who contact us</li>
          <li>Complainants</li>
          <li>Job applicants and our current and former employees.</li>
        </ul>
        <Typography variant="h3" gutterBottom>
          3.1 Visitors to our websites
        </Typography>
        When someone visits&nbsp;<a href="https://www.AMI3GO.com">AMI3GO.com</a>
        &nbsp;we use a third party service, Google Analytics, to collect
        information about how visitors use our site. We do this to find out
        things such as the number of visitors to the various parts of the site.
        We then use the information to compile reports and to help us improve
        our site. This information is only processed in a way which does not
        identify anyone.
        <Typography variant="h3" gutterBottom>
          3.2 Use of cookies by AMI3GO
        </Typography>
        You can read more about how we use cookies in our{" "}
        <Link to={CookieSettings.route.path}>Cookie Settings</Link>.
        <Typography variant="h3" gutterBottom>
          3.3 Electronic Communications
        </Typography>
        Any electronic communication, including any attachments, may be
        monitored and used by us for reasons of security, statistical analysis
        and for monitoring compliance with office policy. Email monitoring or
        blocking software may also be used. Please be aware that you have a
        responsibility to ensure that any email you send to us is within the
        bounds of the law.
        <br />
        <br />
        Information may be passed on to 3<sup>rd</sup> parties in order to carry
        out services and functions on our behalf. Please refer to these
        organisations&apos; privacy notices:
        <ul>
          <li>Microsoft</li>
          <li>Email system hosting</li>
        </ul>
        <Typography variant="h3" gutterBottom>
          3.4 People who make a complaint to us
        </Typography>
        By making a complaint you are consenting to us processing your data to
        resolve your complaint.When we receive a complaint from a person, we
        make up a file containing the details of the complaint. This normally
        contains the identity of the complainant and any other individuals
        involved in the complaint.
        <br />
        <br />
        We will only use the personal information we collect to process and
        resolve the complaint.
        <br />
        <br />
        We may have to disclose the complainant&apos;s identity to whoever the
        complaint is about. This is inevitable where, for example, revealing
        details of a complaint, to the party that is implicated, would identify
        the complainant by association. If a complainant doesn&apos;t want
        information identifying him or her to be disclosed, we will try to
        respect that. However, it may not be possible to handle a complaint on
        an anonymous basis.
        <br />
        <br />
        We will keep personal information contained in complaint files in line
        with our retention policy. This means that information relating to a
        complaint will be retained according to our retention schedule. It will
        be retained in a secure environment and access to it will be restricted
        according to the &apos;need to know&apos; principle.
        <br />
        <br />
        Similarly, where enquiries are submitted to us, we will only use the
        information supplied to us to deal with the enquiry.
        <br />
        <br />
        When we take enforcement action against someone, we may publish the
        identity of the defendant in our Annual Report or elsewhere. Usually we
        do not identify any complainants unless the details have already been
        made public.
        <br />
        <br />
        Information may be passed on to 3<sup>rd</sup> parties in order to carry
        out services and functions on our behalf. Please refer to these
        organisations&apos; privacy notices:
        <ul>
          <li>Microsoft</li>
          <li>Email system hosting</li>
        </ul>
        <Typography variant="h3" gutterBottom>
          3.5 Visitors to AMI3GO offices
        </Typography>
        When individuals visit AMI3GO at one of our office locations we will
        only use the information they supply to us to administer their visit for
        business and site security purposes.
        <br />
        <br />
        Information may be passed on to these 3<sup>rd</sup> parties in order to
        carry out related services and functions on our behalf:
        <ul>
          <li>ProxyClick</li>
          <li>Visitor management system provider</li>
        </ul>
        <Typography variant="h3" gutterBottom>
          3.6 Vacancies mailing list
        </Typography>
        By providing us with your email address you are giving us your consent
        to contact you regarding employment opportunities.
        <br />
        <br />
        We will only use this mailing list to contact you regarding employment
        opportunities at AMI3GO. Your details will not be shared. You may
        unsubscribe at any time and, on receipt of your unsubscribe request,
        your details will be deleted from our vacancies mailing list.
        <br />
        <br />
        Information may be passed on to 3<sup>rd</sup> parties in order to carry
        out services and functions on our behalf. Please refer to these
        organisations&apos; privacy notices:
        <ul>
          <li>Microsoft</li>
          <li>Email system hosting</li>
        </ul>
        <Typography variant="h3" gutterBottom>
          3.7 Job applicants, current and former AMI3GO employees
        </Typography>
        When individuals apply to work at AMI3GO, we will only use the
        information they supply to us to process their application and to
        monitor recruitment statistics.
        <br />
        <br />
        During the recruitment process and after individuals start work at
        AMI3GO information may be passed on to 3<sup>rd</sup> parties in order
        to carry out services and functions on our behalf. Please refer to these
        organisations&apos; privacy notices:
        <ul>
          <li>Workable</li>
          <li>Application processing</li>
          <li>Hireright</li>
          <li>Candidate checks and screening</li>
          <li>CEB</li>
          <li>Aptitude Testing</li>
        </ul>
        Data collected throughout the recruitment process may include background
        screening information, opinions and results of aptitude tests. Data
        published by the applicant on social media and networking profiles may
        also be referred to.
        <br />
        <br />
        Personal information about unsuccessful candidates will be held for 12
        months after the recruitment exercise has been completed, it will then
        be destroyed or deleted. We retain de-personalised statistical
        information about applicants to help inform our recruitment activities,
        but no individuals are identifiable from that data.
        <br />
        <br />
        When providing information to other parties, for example, providing
        references to prospective employers, we will only provide &apos;matter
        of fact&apos; statements, no personal information will be disclosed.
        <Typography variant="h3" gutterBottom>
          3.8 Current and former AMI3GO employees
        </Typography>
        After individuals start work at AMI3GO information may be passed on to 3
        <sup>rd</sup> parties in order to carry out services and functions on
        our behalf.
        <ul>
          <li>Cornerstone</li>
          <li>Performance management</li>
          <li>Hurst</li>
          <li>Payroll processing</li>
          <li>HMRC</li>
          <li>Taxation</li>
          <li>E3 Employment Law LLP</li>
          <li>HR legal advice</li>
          <li>Mercer</li>
          <li>Job role profiling</li>
          <li>AXA</li>
          <li>Occupational healthcare provider</li>
          <li>Chase DeVere</li>
          <li>Pension provider</li>
          <li>WorkBuzz</li>
          <li>Employee surveys</li>
          <li>Great with Talent</li>
          <li>Exit interview provider</li>
          <li>Microsoft</li>
          <li>
            Cloud-based office desktop and collaboration services (Office 365)
          </li>
          <li>Amazon Web Services</li>
          <li>Cloud-based infrastructure and tools</li>
          <li>Okta</li>
          <li>Secure authentication provider</li>
          <li>Secure Code Warrior</li>
          <li>Developer training system provider</li>
          <li>NashTech</li>
          <li>Collaborative offshore development centre</li>
          <li>Recognize</li>
          <li>Employee recognition scheme service provider</li>
          <li>Keepit</li>
          <li>Cloud back-up provider</li>
        </ul>
        When providing information to other parties, for example, providing
        references to prospective employers, we will only provide &apos;matter
        of fact&apos; statements, no personal information will be disclosed.
        <br />
        <br />
        During employment at AMI3GO, technical information from corporate
        devices issued to employees will be collected and monitored for security
        and compliance purposes. This data is stored for a maximum of 1 year. At
        no time is this data used to track the location of AMI3GO employees.
        <br />
        <br />
        AMI3GO process employment and recruitment data where it is necessary for
        the entering and the performance of your employment contract and where
        it is necessary for our legitimate interests.Where we are required to
        process special category data and criminal conviction data we only do so
        where it is necessary for us to fulfill our obligations under employment
        law.
        <br />
        <br />
        Employment records are retained throughout your employment with AMI3GO
        and we will continue to hold your records for a period of time once your
        employment ceases. The length of time information will be held will be
        dependent on the nature of the information and statutory obligations we
        are under.
        <Typography variant="h3" gutterBottom>
          4. Access to personal information
        </Typography>
        You have the right to:
        <ul>
          <li>Access the personal information we hold about you</li>
          <li>Request that inaccurate personal data is corrected</li>
          <li>Request the deletion of your personal data</li>
          <li>
            Request the restriction of the processing of your personal data or
            to object to the processing of your personal data
          </li>
          <li>Request a copy of your data in a portable format.</li>
        </ul>
        To exercise any of these rights, or make a complaint regarding the
        manner in which AMI3GO has processed your personal data, please contact
        our Data Protection Officer using the details below. You will need to
        provide proof of identification.
        <br />
        <br />
        You also have the right to complain to the Information Commissioner
        regarding the processing of your personal data.
        https://ico.org.uk/for-the-public Disclosure of personal information
        <br />
        <br />
        The information you share with us will only be disclosed to our trusted
        3<sup>rd</sup> party service providers for the purposes outlined above
        or to comply with legal requirements.
        <br />
        <br />
        In most circumstances we will not disclose personal data without
        consent. However we may need to share information with other
        organisations or relevant bodies, for example when we investigate a
        complaint, where we are required by law or in connection with legal
        proceedings, or where the disclosure is necessary for the prevention or
        detection of crime
        <br />
        <br />
        AMI3GO reserve the right to disclose information to associated companies
        and third parties as required, in accordance with the terms of the UK
        Data Protection Act 2018.
        <Typography variant="h3" gutterBottom>
          5. Overseas Transfers of Data
        </Typography>
        When we process your data, we may transfer the data to overseas
        locations, either to our hosted cloud services or to services operated
        by our trusted third-party providers. Where data is transferred outside
        the EEA your personal information will continue to be subject to one or
        more appropriate safeguards set out in the UK Data Protection Act 2018.
        These might be the use of model contracts in a form approved by
        regulators, contractual agreements, or having our suppliers sign up to
        an independent privacy scheme approved by regulators (like the US
        &apos;Privacy Shield&apos; scheme).
        <Typography variant="h3" gutterBottom>
          6. Personal Data we Process on behalf of other Data Controllers
        </Typography>
        AMI3GO may act as a data processor for other data controllers.
        <br />
        <br />
        When acting in the role of Data Processor, AMI3GO will only process
        personal information in line with the instructions of the Data
        Controller.
        <br />
        <br />
        When AMI3GO processes personal data on behalf of a Data Controller the
        security measures in place are no less than would be implemented for the
        personal data we process as a Data Controller.
        <br />
        <br />
        Any queries, concerns, or Data Subject Rights requests should be made
        directly to the appropriate data controllers.
        <Typography variant="h3" gutterBottom>
          7. Links to other websites
        </Typography>
        As you use the AMI3GO.com website and load associated resources and
        links you may request page elements and links from third parties.
        Notable third parties are as follows:
        <ul>
          <li>
            <b>YouTube (Google)</b>
            <br />
            We embed videos from both our official YouTube channel and other
            providers&apos; channels. We use YouTube&apos;s standard methods,
            which may set cookies on your computer. Read more at
            https://support.google.com/youtube/answer/171780
          </li>
          <li>
            <b>Maps (Google)</b>
            <br />
            We embed maps from the Google Maps service. We use Google Maps&apos;
            standard methods, which may set cookies on your computer. Read more
            at https://www.google.com/policies/technologies/cookies/
          </li>
          <li>
            <b>Social Media (Various)</b>
            <br />
            We use social media content from a variety of companies including
            Facebook, Twitter and LinkedIn. Accessing these social media posts
            may set cookies on your computer as detailed in the cookie policies
            of the relevant provider.
          </li>
        </ul>
        This privacy notice does not cover the links within this site linking to
        other websites. We encourage you to read the privacy statements on the
        other websites you visit.
        <br />
        <br />
        <Typography variant="h3" gutterBottom>
          8. Changes to this privacy notice
        </Typography>
        We keep our privacy notice under regular review. This privacy notice was
        last updated on 09/01/2020. Any changes we make to our privacy policy in
        the future will be posted on this page and, where appropriate, notified
        to you by e-mail. Please check back frequently to see any updates or
        changes to our privacy policy.
        <Typography variant="h3" gutterBottom>
          9. How to contact us
        </Typography>
        If you want to request any further information about our privacy policy
        or would like to submit any comments or requests you can email us or
        write to:
        <ul>
          <li>
            Data Protection Officer, AMI3GO Ltd, Colony, 5 Piccadilly Place,
            Manchester, M1 3BR
          </li>
          <li>
            <MuiLink href="mailto:enquiry@ami3go.com">
              enquiry@ami3go.com
            </MuiLink>
          </li>
        </ul>
        <Typography variant="h3" gutterBottom>
          10 Intellectual Property Rights
        </Typography>
        In the event that the ownership of AMI3GO or the ownership or operation
        of this website is transferred to another party, the ownership and
        access to any data collected, processed or stored will transfer to the
        new owner.
      </Container>
    </>
  );
});

const page: IPage = {
  title: "Privacy policy",
  route: {
    path: "/privacy-policy",
    element: <PageComponent />,
  },
};

export default page;
