import * as React from "react";

import heroImage from "../../assets/stock/people.webp";
import { HeroBanner } from "../../components";
import { ContactUs as ContactUsSection } from "../../components/sections";
import { IPage } from "..";

import ASGMASection from "./ASGMASection";
import CoreValuesSection from "./CoreValuesSection";
import OurApproachSection from "./OurApproachSection";

const PageComponent = React.memo(function Page() {
  return (
    <>
      <HeroBanner
        image={heroImage}
        heading="Who we are"
        subHeading="Advisors, strategists, and engineers focused on people, above all else."
      />
      <OurApproachSection />
      <CoreValuesSection />
      <ASGMASection />
      <ContactUsSection id="contact-us-section" />
    </>
  );
});

const page: IPage = {
  title: "Who we are",
  route: {
    path: "/who-we-are",
    element: <PageComponent />,
  },
  children: [
    { title: "Our Approach", hash: "our-approach" },
    { title: "Core Values", hash: "core-values" },
    { title: "ASGMA", hash: "asgma" },
  ],
};

export default page;
