import * as React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import {
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import heroImage from "../../assets/stock/pexels-ketut-subiyanto-4963437.webp";
import { HeroBanner } from "../../components";
import { IPage, PrivacyPolicy } from "..";

const consentCookieName = "cookieconsent_status";

const PageComponent = React.memo(function Page() {
  const [isCookiesAllowed, setIsCookiesAllowed] = React.useState<
    null | boolean
  >(null);
  const [cookies, setCookie, removeCookie] = useCookies();

  React.useEffect(() => {
    const cookieValue = cookies[consentCookieName];
    setIsCookiesAllowed(cookieValue !== "0");
  }, [cookies]);

  const handleCookiePreferenceChange = (
    _event: React.MouseEvent<HTMLElement>,
    newIsCookiesAllowed: boolean
  ) => {
    setIsCookiesAllowed(newIsCookiesAllowed);
  };

  React.useEffect(() => {
    if (isCookiesAllowed === null) {
      return;
    }
    const newIsCookiesAllowed = isCookiesAllowed ? "1" : "0";
    const cookieValue = cookies[consentCookieName];

    if (newIsCookiesAllowed === cookieValue) {
      return; // already set
    }
    setCookie(consentCookieName, newIsCookiesAllowed);

    if (!isCookiesAllowed) {
      Object.keys(cookies)
        .filter((c) => c !== consentCookieName)
        .forEach((c) => removeCookie(c)); // remove all cookies
    }
  }, [isCookiesAllowed]);

  return (
    <>
      <HeroBanner image={heroImage} heading="Cookie settings" />
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Typography variant="body1" gutterBottom>
          This website stores cookies on your computer. These cookies are used
          to collect information about how you interact with our website and
          allow us to remember you. We use this information in order to improve
          and customize your browsing experience and for analytics and metrics
          about our visitors both on this website and other media. To find out
          more about the cookies we use, see our{" "}
          <Link to={PrivacyPolicy.route.path}>Privacy Policy</Link>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you choose to disable cookies for this site, your information
          won&apos;t be tracked when you visit this website. A single cookie
          will be used in your browser to remember your preference not to be
          tracked. Change your preferences by selecting the setting below.
        </Typography>

        <ToggleButtonGroup
          color="secondary"
          value={isCookiesAllowed}
          exclusive
          onChange={handleCookiePreferenceChange}
        >
          <ToggleButton value={true}>
            Yes, I consent to the use of cookies
          </ToggleButton>
          <ToggleButton value={false}>
            No, i do not consent to the use of cookies
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>
    </>
  );
});

const page: IPage = {
  title: "Cookie settings",
  route: {
    path: "/cookie-settings",
    element: <PageComponent />,
  },
};

export default page;
