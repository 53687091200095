import React from "react";
import { Container, Typography } from "@mui/material";

import sectionImage from "../../assets/stock/pexels-ketut-subiyanto-4963437.webp";
import { Type03Right } from "../../components/sections";

export default React.memo(function OurApproachSection() {
  return (
    <Container maxWidth="lg">
      <Type03Right id="our-approach" title="Our Approach" image={sectionImage}>
        <Typography variant="h3" gutterBottom>
          Our Approach
        </Typography>
        <Typography variant="h4" gutterBottom>
          Personal connection at global scale
        </Typography>
        <Typography variant="body1" gutterBottom>
          AMI3GO is a through and through technology company. We get to the core
          of your requirements by developing and maintaining a relationship with
          our clients. We pride ourselves on creating a partnership built to
          last. It&apos;s important to us to build a future for you that we can
          be part of.
        </Typography>
      </Type03Right>
    </Container>
  );
});
