import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import sectionImage from "../../assets/stock/luca-bravo-a6x7YPiNxwI-unsplash.jpg";
import { Type01Right } from "../../components/sections";
import { WhatWeDo } from "..";

export default React.memo(function WhatWeDoSection() {
  return (
    <Type01Right
      id="what-we-do-section"
      title="What we do"
      image={sectionImage}
    >
      <Typography variant="h3" gutterBottom>
        What we do
      </Typography>
      <Typography variant="h4" gutterBottom>
        AMI3GO is a professional services company focused on digital strategy,
        technology, consulting, operations and business transformation.
        Delivering software solutions that utilise data driven decisions to
        unlock your potential.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We provide digital strategy and enterprise architecture that will add
        leading edge innovation to your business. Placing data at the core of
        what we do allows us to perform intensive analysis of your data to
        create incredibly insightful digital experiences by engaging the very
        latest technology. We help you build your future by creating strategy
        plans, using automation plans such as omni-channel commerce and by
        analysing your customer feedback.
      </Typography>

      <Button
        variant="outlined"
        color="secondary"
        component={Link}
        to={WhatWeDo.route.path}
        size="large"
        sx={{
          my: 2,
        }}
      >
        {WhatWeDo.title}
      </Button>
    </Type01Right>
  );
});
