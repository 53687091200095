import React from "react";
import { Container, Typography } from "@mui/material";

import sectionImage from "../../assets/logo/cloud-platform-logo-amazon.svg";
import { Type03Left } from "../../components/sections";

export default React.memo(function AmazonAWSSection() {
  return (
    <Container maxWidth="lg">
      <Type03Left id="amazon-aws" title="Amazon AWS" image={sectionImage}>
        <Typography variant="h3" gutterBottom>
          Amazon AWS
        </Typography>
        <Typography variant="h4" gutterBottom>
          Get maximum value from the AWS cloud, from planning and migration to
          all that comes next.
        </Typography>
      </Type03Left>
    </Container>
  );
});
