import * as React from "react";

import { ContactUs as ContactUsSection } from "../../components/sections";
import { IPage } from "..";

import Landing from "./Landing";
import TechSection from "./TechSection";
import WhatWeDoSection from "./WhatWeDoSection";
import WhoWeAreSection from "./WhoWeAreSection";

const PageCompnent = React.memo(function Page() {
  return (
    <>
      <Landing />
      <WhatWeDoSection />
      <WhoWeAreSection />

      <TechSection />

      <ContactUsSection id="contact-us-section" />
    </>
  );
});

const page: IPage = {
  title: "Home",
  route: {
    path: "*",
    element: <PageCompnent />,
  },
};

export default page;
