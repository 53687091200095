import * as React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import topbarLogo from "../assets/logo/company-logo-blue.svg";
import { useHandleNavigation } from "../hooks";
import { ContactUs, IPage, WhatWeDo, WhoWeAre } from "../pages";

type PageItem = {
  page: IPage;
  title: string;
  variant: "text" | "outlined" | "contained";
};

const pages: PageItem[] = [
  {
    page: WhatWeDo,
    title: WhatWeDo.title,
    variant: "text",
  },
  {
    page: WhoWeAre,
    title: WhoWeAre.title,
    variant: "text",
  },
  {
    page: ContactUs,
    title: "Get in touch",
    variant: "outlined",
  },
];

export default React.memo(function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { navigate } = useHandleNavigation();

  const handleNavigation = (page: IPage) => {
    navigate(page);
    setIsMenuOpen(false);
  };

  const logo = (isSmallScreen: boolean) => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        {" "}
        <a href="/">
          <Box
            sx={{
              height: 70,
              width: "auto",
              background: `url(${topbarLogo}) no-repeat ${
                isSmallScreen ? "center" : "left"
              } center/contain`,
            }}
          />
        </a>
      </Box>
    );
  };

  const largeScreen = () => {
    return (
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          width: 1,
          alignItems: "center",
        }}
      >
        {logo(false)}

        <Box sx={{ flexShrink: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((pageItem) => (
            <Button
              key={pageItem.page.route.path}
              variant={pageItem.variant}
              component={Link}
              to={pageItem.page.route.path}
              onClick={() => setIsMenuOpen(false)}
              size="large"
              sx={{
                mx: 1,
                borderColor: (theme) => theme.ami3goPalette.green.contrastText,
                color: (theme) => theme.ami3goPalette.green.contrastText,
                display: "block",
              }}
            >
              {pageItem.title}
            </Button>
          ))}
        </Box>
      </Box>
    );
  };

  const smallScreen = () => {
    return (
      <>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            width: 1,
            alignItems: "center",
          }}
        >
          {logo(true)}

          <Box sx={{ flexShrink: 0 }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setIsMenuOpen(true)}
              color="inherit"
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          </Box>
        </Box>
        <Drawer
          anchor="right"
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          {pages.map((pageItem) => (
            <MenuItem
              key={pageItem.page.route.path}
              onClick={() => handleNavigation(pageItem.page)}
              sx={{ padding: 3 }}
            >
              <Typography textAlign="center">{pageItem.title}</Typography>
            </MenuItem>
          ))}
        </Drawer>
      </>
    );
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {largeScreen()}
          {smallScreen()}
        </Toolbar>
      </Container>
    </AppBar>
  );
});
