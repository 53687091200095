import React from "react";
import { Typography, useTheme } from "@mui/material";

import sectionImage from "../../assets/stock/stephen-dawson-qwtCeJ5cLYs-unsplash.jpg";
import { Type01Right } from "../../components/sections";

export default React.memo(function CloudMigrationsSection() {
  const theme = useTheme();
  return (
    <Type01Right
      id="cloud-migrations"
      title="Cloud Migrations"
      image={sectionImage}
      palette={theme.ami3goPalette.green}
    >
      <Typography variant="h3" gutterBottom>
        Cloud Migrations
      </Typography>
      <Typography variant="h4" gutterBottom>
        How can AMI3GO ensure your cloud migration is a success?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Our experts employ a tailored, risk-mitigated cloud strategy that
        considers all possible deployment models, with minimal downtime. We
        provide a seamless, modern, secure and agile journey from in-house IT to
        a cloud infrastructure.
      </Typography>
      <Typography variant="body1" gutterBottom>
        With our promise of digital transformation at speed and scale, we bring
        together applications, infrastructure and security to leverage the full
        potential of cloud. No matter where you are in your digital journey, we
        can help you extract maximum value across the complete life-cycle and
        distance yourself from the competition.
      </Typography>
    </Type01Right>
  );
});
