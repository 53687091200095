import * as React from "react";
import { useNavigate } from "react-router-dom";

import { IPage } from "../pages";

export default function useHandleNavigation() {
  const naviagte = useNavigate();

  const navigate = React.useCallback((page: IPage) => {
    naviagte(page.route.path);
  }, []);

  return { navigate };
}
