import * as React from "react";

import cloudLogoAmazon from "../../assets/logo/cloud-platform-logo-amazon.svg";
import cloudLogoAzure from "../../assets/logo/cloud-platform-logo-azure.svg";
import cloudLogoGoogle from "../../assets/logo/cloud-platform-logo-google.svg";
import heroImage from "../../assets/stock/pexels-pixabay-210158.webp";
import { HeroBanner } from "../../components";
import { ContactUs as ContactUsSection } from "../../components/sections";
import { IPage } from "..";

import AmazonAWSSection from "./AmazonAWSSection";
import GoogleCloudSection from "./GoogleCloudSection";
import MicrosoftAzureSection from "./MicrosoftAzureSection";

const PageComponent = React.memo(function Page() {
  return (
    <>
      <HeroBanner
        image={heroImage}
        heading="Platforms"
        subHeading="Our team of specialists and experts have experience with various technologies"
      />

      <MicrosoftAzureSection />
      <GoogleCloudSection />
      <AmazonAWSSection />

      <ContactUsSection id="contact-us-section" />
    </>
  );
});

const page: IPage = {
  title: "Platforms",
  route: {
    path: "/platforms",
    element: <PageComponent />,
  },
  children: [
    {
      title: "Microsoft Azure",
      hash: "microsoft-azure",
      image: cloudLogoAzure,
    },
    { title: "Amazon AWS", hash: "amazon-aws", image: cloudLogoAmazon },
    { title: "Google Cloud", hash: "google-cloud", image: cloudLogoGoogle },
  ],
};

export default page;
