import React from "react";
import { Typography, useTheme } from "@mui/material";

import sectionImage from "../../assets/logo/cloud-platform-logo-azure.svg";
import { Type01Right } from "../../components/sections";

export default React.memo(function MicrosoftAzureSection() {
  const theme = useTheme();
  return (
    <Type01Right
      id="microsoft-azure"
      title="Microsoft Azure"
      image={sectionImage}
      palette={theme.ami3goPalette.green}
    >
      <Typography variant="h3" gutterBottom>
        Microsoft Azure
      </Typography>
      <Typography variant="h4" gutterBottom>
        We&apos;re committed to helping you and your business innovate with
        Microsoft&apos;s vast technology suite.
      </Typography>
      <ul>
        <li>Microsoft Azure</li>
        <li>Microsoft Dynamics CRM</li>
        <li>Microsoft Office 365</li>
        <li>Microsoft Power BI</li>
      </ul>
    </Type01Right>
  );
});
