import * as React from "react";
import { Box, Typography } from "@mui/material";

import ContactUs from "../ContactUs";

type SectionProps = {
  id: string;
};

export default React.memo(function Section(props: SectionProps) {
  return (
    <>
      <Box
        id={props.id}
        component="section"
        sx={{
          my: 10,
        }}
      >
        <Box
          sx={{
            p: 5,
            backgroundColor: (theme) => theme.ami3goPalette.lightBlue.main,
            color: (theme) => theme.ami3goPalette.lightBlue.contrastText,
            textAlign: "center",
          }}
        >
          <Typography variant="h3">Lets talk ...</Typography>
        </Box>

        <ContactUs />
      </Box>
    </>
  );
});
