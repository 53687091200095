import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";

import { Platforms } from "..";

export default React.memo(function Landing() {
  return (
    <Box id="technology-section" component="section" sx={{ pt: 10 }}>
      <Container>
        <Typography variant="h3" gutterBottom>
          Technology Platforms
        </Typography>
        <Typography variant="h4" gutterBottom>
          Microsoft certified engineers and cloud technology experts in Azure
          &amp; AWS. Maximise your efficiency and get your costs under control
          by migrating your on-prem systems to the cloud with AMI3GO.
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            "& img": { maxHeight: { xs: "100px", lg: "200px" }, mx: 2 },
          }}
        >
          {Platforms.children &&
            Platforms.children.map((i) => (
              <Link
                key={i.hash}
                to={{ pathname: Platforms.route.path, hash: i.hash }}
              >
                <img src={i.image} alt={i.title} loading="lazy" />
              </Link>
            ))}
        </Box>
      </Container>
    </Box>
  );
});
