import * as React from "react";
import { Router } from "react-router-dom";
import { History } from "history";

interface IProps {
  children: React.ReactNode;
  history: History;
}

export default function CustomRouter(props: IProps) {
  const [state, setState] = React.useState({
    action: props.history.action,
    location: props.history.location,
  });

  React.useLayoutEffect(() => props.history.listen(setState), [props.history]);

  React.useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        ?.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  }, [props.history.location]);

  return (
    <Router
      basename=""
      location={state.location}
      navigationType={state.action}
      navigator={props.history}
    >
      {props.children}
    </Router>
  );
}
