import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import sectionImage from "../../assets/stock/Content-with-background-image-3010x1200.jpg";
import { Type02Right } from "../../components/sections";
import { WhoWeAre } from "..";

export default React.memo(function WhoWeAreSection() {
  return (
    <Type02Right id="who-we-are-section" image={sectionImage}>
      <Typography variant="h3" gutterBottom>
        Who we are
      </Typography>
      <Typography variant="h4" gutterBottom>
        Strategists, data fanatics and engineers focused on humans.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Drawing on our years of experience across various industries and by
        leveraging our expertise gained by integrating with leading technology
        providers we can help you navigate the complexity of digital
        transformation and provide proven, best fit solutions that make your
        desired transformation from &apos;what is&apos; to &apos;what could
        be&apos; a reality.
      </Typography>

      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={WhoWeAre.route.path}
        size="large"
        sx={{
          my: 2,
        }}
      >
        Get to know us
      </Button>
    </Type02Right>
  );
});
