import React from "react";
import { Link as Scroll } from "react-scroll";
import {
  Box,
  Fade,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../../assets/logo/company-logo-green.svg";

export default React.memo(function Landing() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const colour = theme.ami3goPalette.darkGrey;
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setChecked(true), 500);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: colour.main,
        color: colour.contrastText,
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Fade in={checked} {...(checked ? { timeout: 1000 } : {})}>
          <Box>
            <img
              src={logo}
              style={{ maxHeight: "40vh", maxWidth: "70vh" }}
              alt="Welcome to AMI3GO"
              loading="lazy"
            />
            <Typography variant="h1">Welcome to AMI3GO</Typography>
            <Scroll to="what-we-do-section" smooth={true}>
              <IconButton>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ color: colour.contrastText }}
                  className="fa-beat-fade"
                  size={isSmallScreen ? "3x" : "6x"}
                />
              </IconButton>
            </Scroll>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
});
