import React from "react";
import { Typography } from "@mui/material";

import sectionImage from "../../assets/stock/Content-with-background-image-3010x1200.jpg";
import { Type02Left } from "../../components/sections";

export default React.memo(function TechnologyDevelopmentSection() {
  return (
    <Type02Left id="technology-development" image={sectionImage}>
      <Typography variant="h3" gutterBottom>
        Technology Development
      </Typography>
      <Typography variant="h4" gutterBottom>
        Digital engineering transforms customer journeys, adding futurism &amp;
        innovation, and industry-first automation technology resets customer
        expectations.
      </Typography>
      <Typography variant="body1" gutterBottom>
        At AMI3GO, we will partner with you to modernize your core IT software.
        By creating an actionable reference architecture that charts the course
        from &quot;as is&quot; to &quot;to be,&quot; we&apos;ll help you
        transform your applications and infrastructure from a business-limiting
        state to a growth-enabling one.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We pride ourselves on building a team of craftsmen, specialising in
        their area of expertise, to ensure you solution has performance at its
        core, while maintaining the highest quality.
      </Typography>
    </Type02Left>
  );
});
