import * as React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  styled,
  useTheme,
} from "@mui/material";

import logo from "../assets/logo/company-logo-green.svg";
import {
  ContactUs,
  CookieSettings,
  Platforms,
  PrivacyPolicy,
  TermsOfUse,
  WhatWeDo,
  WhoWeAre,
} from "../pages";
import { applicationSettings } from "../utils";

import { SocialMediaList } from ".";

const mainPages = [WhatWeDo, WhoWeAre, Platforms];
const otherPages = [PrivacyPolicy, CookieSettings, TermsOfUse, ContactUs];

const LinksList = styled("ul")(() => {
  return {
    margin: 0,
    padding: 0,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    listStyle: "none",
    "& li": {
      position: "relative",
      display: "block",
      a: {
        color: "#fff",
        textDecoration: "none",
        textTransform: "none",

        "&:hover, &:focus": {
          textDecoration: "underline",
          backgroundColor: "transparent",
        },
      },
    },
    "& hr": {
      borderColor: "#fff",
    },
  };
});

export default React.memo(function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: 1,
        backgroundColor: (theme) => theme.ami3goPalette.darkGrey.main,
        color: "#fff",
        textAlign: "center",
        p: 2,
        mt: 3,
      }}
    >
      <Container sx={{ pb: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={7} lg={5} xl={4}>
            <img
              src={logo}
              style={{
                padding: 3,
                maxWidth: "100%",
                maxHeight: "200px",
                height: "auto",
              }}
              alt="AMI3GO Ltd"
              loading="lazy"
            />

            <SocialMediaList
              color={theme.ami3goPalette.darkGrey.main}
              backgroundColor={theme.ami3goPalette.darkGrey.contrastText}
            />
          </Grid>

          <Grid item md={5} lg={7} xl={8}>
            <Grid container spacing={3}>
              {mainPages.map((page) => (
                <Grid key={page.route.path} item xs={12} lg={4}>
                  <LinksList>
                    <li>
                      <Button
                        variant="text"
                        component={Link}
                        to={page.route.path}
                      >
                        {page.title}
                      </Button>
                    </li>
                    {page.children && (
                      <>
                        <Divider />

                        {page.children &&
                          page.children.map((pageChild) => (
                            <li key={pageChild.hash}>
                              <Button
                                variant="text"
                                component={Link}
                                to={{
                                  pathname: page.route.path,
                                  hash: pageChild.hash,
                                }}
                              >
                                {pageChild.title}
                              </Button>
                            </li>
                          ))}
                      </>
                    )}
                  </LinksList>
                </Grid>
              ))}
              <Grid item xs={12}>
                <LinksList>
                  <Divider />
                  {otherPages.map((page) => (
                    <li key={page.route.path}>
                      <Button
                        variant="text"
                        component={Link}
                        to={page.route.path}
                      >
                        {page.title}
                      </Button>
                    </li>
                  ))}
                </LinksList>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <small>
        <span data-testid="app-copyright">
          AMI3GO Ltd. All rights reserved.
        </span>
        <br />
        <span data-testid="app-version">
          {applicationSettings.applicationVersion}
        </span>
      </small>
    </Box>
  );
});
