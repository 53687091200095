declare global {
  interface Window {
    _env_: any;
  }
}

export const applicationSettings = {
  applicationName: window._env_?.APPLICATION_NAME ?? "AMI3GO Company Website",
  applicationVersion: window._env_?.APPLICATION_VERSION ?? "0.0.0.0",
  applicationInsightsInstrumentationKey:
    window._env_?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY ?? "TBC",
};
