import "react-responsive-carousel/lib/styles/carousel.min.css";

import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Box, Container, styled, Typography } from "@mui/material";

import betterFutureIcon from "../../assets/values/better-future.svg";
import celebrateIcon from "../../assets/values/celebrate.svg";
import focusIcon from "../../assets/values/focus.svg";
import growthIcon from "../../assets/values/growth.svg";
import humbleIcon from "../../assets/values/humble.svg";
import inspireIcon from "../../assets/values/inspire.svg";
import ownershipIcon from "../../assets/values/ownership.svg";
import peopleIcon from "../../assets/values/people.svg";
import rightThing from "../../assets/values/right-thing.svg";
import smileIcon from "../../assets/values/smile.svg";

type carouselItem = {
  image: string;
  caption: string;
};
const carouselData: carouselItem[] = [
  {
    image: rightThing,
    caption: "Do what is right, always.",
  },
  {
    image: peopleIcon,
    caption: "Drive connection and teamwork.",
  },
  {
    image: inspireIcon,
    caption: "Inspire passion and adventure.",
  },
  {
    image: ownershipIcon,
    caption: "Take ownership. Get it done.",
  },
  {
    image: celebrateIcon,
    caption: "Celebrate authenticity.",
  },
  {
    image: focusIcon,
    caption: "Focus on outcomes.",
  },
  {
    image: growthIcon,
    caption: "Fuel growth and innovation.",
  },
  {
    image: humbleIcon,
    caption: "Stay humble and curious.",
  },
  {
    image: betterFutureIcon,
    caption: "Build and shape a better future.",
  },
  {
    image: smileIcon,
    caption: "Smile.",
  },
];

const StyledSection = styled(Box)(({ theme }) => {
  return {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    "h3, h4": {
      textAlign: "center",
    },
    ".carousel": {
      "& .slide img": {
        maxWidth: "250px",
        maxHeight: "250px",
      },
      "& .control-dots": {
        margin: 0,
        position: "relative",
        "& .dot": {
          backgroundColor: theme.ami3goPalette.green.main,
          width: "12px",
          height: "12px",
          boxShadow: theme.shadows[2],
          opacity: 0.5,
          "&.selected": { opacity: 1 },
        },
      },
      "& .control-arrow": {
        backgroundColor: theme.ami3goPalette.green.light,
        "&:hover": {
          backgroundColor: theme.ami3goPalette.green.main,
        },
        "&:before": {
          borderRightColor: theme.ami3goPalette.green.contrastText,
          borderLeftColor: theme.ami3goPalette.green.contrastText,
        },
      },
    },
  };
});

export default React.memo(function CoreValuesSection() {
  return (
    <StyledSection id="core-values" component="section">
      <Container maxWidth="lg">
        <Typography variant="h3" gutterBottom>
          Core values
        </Typography>
        <Typography variant="h4" gutterBottom>
          Our core values are an active part of everything we do. Each one
          reminds us to stay true to ourselves while driving amazing outcomes
          for our clients
        </Typography>
      </Container>

      <Container maxWidth="md">
        <Carousel emulateTouch showStatus={false} showThumbs={false}>
          {carouselData.map((item) => (
            <Box sx={{ padding: 3 }} key={item.caption}>
              <img src={item.image} />
              <Typography variant="body1">{item.caption}</Typography>
            </Box>
          ))}
        </Carousel>
      </Container>
    </StyledSection>
  );
});
