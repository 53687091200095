import * as React from "react";
import { Box, Container, Grid, SimplePaletteColorOptions } from "@mui/material";

type SectionProps = {
  id: string;
  title: string;
  image: string;
  children?: React.ReactNode;
  palette?: SimplePaletteColorOptions;
};

export default React.memo(function Section(props: SectionProps) {
  return (
    <Box
      id={props.id}
      component="section"
      sx={{
        my: 10,
        position: "relative",
        "&:before": {
          content: '""',
          display: "block",
          height: "70px",
          marginTop: "-70px",
          visibility: "hidden",
        },
      }}
    >
      <Box
        sx={{
          "&:before": {
            content: '""',
            display: "block",
            backgroundColor: (theme) =>
              (props.palette ?? theme.ami3goPalette.lightBlue).main,
            height: 1,
            top: 0,
            right: 0,
            float: "right",
            position: "absolute",
            zIndex: -1,
            width: {
              xs: 1,
              lg: 0.6,
            },
          },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={7}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <img
                src={props.image}
                style={{
                  marginTop: "6.2rem",
                  marginBottom: "2rem",
                  maxWidth: "100%",
                  height: "auto",
                }}
                alt={props.title}
                loading="lazy"
              />
            </Grid>
            {props.children && (
              <Grid item xs={12} lg={5}>
                <Box>{props.children}</Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
});
