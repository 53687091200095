import React from "react";
import { Typography } from "@mui/material";

import sectionImage from "../../assets/stock/Content-with-background-image-3010x1200.jpg";
import { Type02Left } from "../../components/sections";

export default React.memo(function GoogleCloudSection() {
  return (
    <Type02Left id="google-cloud" image={sectionImage}>
      <Typography variant="h3" gutterBottom>
        Google Cloud
      </Typography>
      <Typography variant="h4" gutterBottom>
        Leave costly overhead behind and reach your goals faster.
      </Typography>
    </Type02Left>
  );
});
