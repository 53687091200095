import * as React from "react";

import heroImage from "../../assets/stock/hex-pattern.webp";
import { HeroBanner } from "../../components";
import { ContactUs as ContactUsSection } from "../../components/sections";
import { IPage } from "..";

import AIMachineLearningSection from "./AIMachineLearningSection";
import CloudMigrationsSection from "./CloudMigrationsSection";
import ConsultancySection from "./ConsultancySection";
import TechnologyDevelopmentSection from "./TechnologyDevelopmentSection";

const PageComponent = React.memo(function Page() {
  return (
    <>
      <HeroBanner
        image={heroImage}
        heading="What we do"
        subHeading="We build your future by aligning digital strategy with your company roadmap"
      />
      <ConsultancySection />
      <TechnologyDevelopmentSection />
      <CloudMigrationsSection />
      <AIMachineLearningSection />
      <ContactUsSection id="contact-us-section" />
    </>
  );
});

const page: IPage = {
  title: "What we do",
  route: {
    path: "/what-we-do",
    element: <PageComponent />,
  },
  children: [
    { title: "Consultancy", hash: "consultancy" },
    { title: "Technology Development", hash: "technology-development" },
    { title: "Cloud Migrations", hash: "cloud-migrations" },
    { title: "AI Machine Learning", hash: "ai-machine-learning" },
  ],
};

export default page;
