import React from "react";
import { Button, Container, Typography } from "@mui/material";

import sectionImage from "../../assets/logo/asgma-logo.webp";
import { Type03Left } from "../../components/sections";

export default React.memo(function ASGMASection() {
  return (
    <Container maxWidth="lg">
      <Type03Left id="asgma" title="ASGMA" image={sectionImage}>
        <Typography variant="h3" gutterBottom>
          ASGMA
        </Typography>
        <Typography variant="h4" gutterBottom>
          AMI3GO is proud to sponsor ASGMA - Autistic Society for the Greater
          Manchester Area
        </Typography>
        <Typography variant="body1" gutterBottom>
          ASGMA provide support for parents & carers through their Autism
          Information and Family Support Project and direct support for people
          with autistic spectrum conditions, including Asperger Syndrome/Higher
          Functioning Autism through their Aspirations Project and Autism
          Lifeskills Project.
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          href="https://asgma.org.uk"
          size="large"
          target="_blank"
          sx={{
            my: 2,
          }}
        >
          See more
        </Button>
      </Type03Left>
    </Container>
  );
});
