import { createBrowserHistory, createMemoryHistory } from "history";

export const createHistory = () => {
  if (
    process.env.JEST_WORKER_ID === undefined ||
    process.env.NODE_ENV !== "test"
  ) {
    return createBrowserHistory();
  }

  return createMemoryHistory();
};
