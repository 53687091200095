import * as React from "react";
import { styled } from "@mui/material";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SocialMediaListProps = {
  color: string;
  backgroundColor?: string;
};

const StyledList = styled("ul")<SocialMediaListProps>((props) => {
  const iconSize = 50;
  return {
    padding: 0,
    margin: 0,
    textAlign: "center",
    display: "block",
    overflow: "hidden",
    listStyle: "none",
    "& li": {
      display: "inline-block",
      marginRight: props.theme.spacing(1),
      a: {
        display: "block",
        width: iconSize,
        height: iconSize,
        textAlign: "center",
        lineHeight: `${iconSize + 5}px`,
        fontSize: iconSize / 2,
        color: props.color,
        backgroundColor: props.backgroundColor,
        borderRadius: "50%",

        "&:hover, &:focus": {
          textDecoration: "none",
        },
      },
    },
  };
});

const socialMediaItems = [
  {
    name: "LinkedIn",
    icon: faLinkedinIn,
    url: "https://www.linkedin.com/company/ami3go",
  },
  {
    name: "Twitter @amigoltd",
    icon: faTwitter,
    url: "https://twitter.com/ami3goltd",
  },
  {
    name: "Facebook @ami3go",
    icon: faFacebookF,
    url: "https://www.facebook.com/ami3go",
  },
  {
    name: "Instagram @ami3go",
    icon: faInstagram,
    url: "https://www.instagram.com/ami3go",
  },
  {
    name: "YouTube",
    icon: faYoutube,
    url: "https://www.youtube.com/channel/UC4lj7psRKSrFxhu3K2-RWSw",
  },
];

export default React.memo(function SocialMediaList(
  props: SocialMediaListProps
) {
  return (
    <StyledList {...props}>
      {socialMediaItems.map((i) => (
        <li key={i.name}>
          <a href={i.url} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={i.icon} />
          </a>
        </li>
      ))}
    </StyledList>
  );
});
